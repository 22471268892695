import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Menu from "./Menu"
import UniversalLink from "./UniversalLink"
import ToggleIcon from "../assets/svg/toggle.inline.svg"
const Header = ({ pageContext, toggleBackdrop, ...props }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)
  return (
    <header id="site-header" className="header-footer-group" role="banner">
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Raleway:wght@300;400;500;600;700;800&display=swap" rel="stylesheet"></link>
      <div className="header-inner section-inner">
        <div className="header-titles-wrapper">
          <div className='nav-logo'><UniversalLink to="/"><img src="/static/LOGO-eae55e0ffada33600a7d1e00dd110daa.png" width="289px" alt='Liberty Waterproofing LLC - Protect What Matters Most' /></UniversalLink></div>
        </div>

        <div className="header-navigation-wrapper">
          <Menu />
     
     
          <button
            className="toggle nav-toggle mobile-nav-toggle"
            data-toggle-target=".menu-modal"
            data-toggle-body-class="showing-menu-modal"
            aria-expanded="false"
            data-set-focus=".close-nav-toggle"
            onClick={(e) => toggleBackdrop(e, true)}
          >
         <img src="/static/burger-8bbdca4e34851af242035c1b0386bdad.png"></img>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
