import React, { useEffect, useRef } from "react";
import CloseIcon from "../assets/svg/close.inline.svg"
import { graphql, useStaticQuery } from "gatsby"
import stopPropagation from "../utils/stop-propagation"
import UniversalLink from "./UniversalLink"
import SocialMenu from "./SocialMenu"
import $ from "jquery";

const MenuModal = ({ isActive, toggleBackdrop }) => {
    const myContainer = useRef(null);
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "extended" }) {
        name
        menuItems {
          nodes {
            label
            childItems {
                nodes {
                  url
                  databaseId
                  label
                  connectedNode {
                    node {
                      ... on WpContentNode {
                        uri
                      } 
                    }
                  }
                }
              }
            url
            databaseId
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                } 
              }
            }
          }
        }
      }
    }
  `)
 
  const myHandler = (e) => {
   console.log(myContainer.current);
   myContainer.current.classList.toggle("mobile-dropdown-active");
  };

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

  return (
    <div
      className={
        "menu-modal cover-modal header-footer-group show-modal" +
        (isActive ? " active" : "")
      }
      data-modal-target-string=".menu-modal"
      onClick={(e) => toggleBackdrop(e, false)}
    >
      <div onClick={stopPropagation} className="menu-modal-inner modal-inner">
        <div className="menu-wrapper section-inner">
          <div className="menu-top">
            <button
              className="toggle close-nav-toggle fill-children-current-color"
              data-toggle-target=".menu-modal"
              data-toggle-body-class="showing-menu-modal"
              aria-expanded="false"
              data-set-focus=".menu-modal"
              onClick={(e) => toggleBackdrop(e, false)}
            >
              <span className="toggle-text">Close Menu</span>
              <CloseIcon />
            </button>

            <nav
              className="expanded-menu"
              aria-label="Expanded"
              role="navigation"
            >
              <ul className="modal-menu reset-list-style">
                {wpMenu.menuItems.nodes.map((menuItem, i) => {
                  const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url

                  const itemId = "modal-menu-item-" + menuItem.databaseId
                    if (menuItem.childItems.length < 1) {
                  return (
                    <li
                      id={itemId}
                      key={itemId}
                      className={
                        "menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home " +
                        itemId 
                        
                      }
                      onClick={(e) => toggleBackdrop(e, false)}
                    >
                      <div className="ancestor-wrapper">
                        <UniversalLink
                          to={path}
                          activeClassName={
                            "current-menu-item current_page_item"
                          }
                        >
                          {menuItem.label}
                        </UniversalLink>
                      </div>
                    </li>
                  )
                    }else{
                  return (
                      <div class="dropdown"  id={menuItem.childItems.length}>
                            {menuItem.childItems.nodes.map((menuItem, i) => {
                                  const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;
                                  const itemId = "modal-menu-item-" + menuItem.databaseId;

                    <li
                      id={itemId}
                      key={itemId}
                      className={
                        "menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home " +
                        itemId
                      }
                      onClick={(e) => toggleBackdrop(e, false)}
                    >
                      <div className="ancestor-wrapper">
                        <UniversalLink
                          to={path}
                          activeClassName={
                            "current-menu-item current_page_item"
                          }
                        >
                          {menuItem.label}
                        </UniversalLink>
                      </div>
                    </li>
                     }) }
                    </div>
                  )
                    }
                   
                })}
                
              </ul>
            </nav>

            <nav className="mobile-menu" aria-label="Mobile" role="navigation">
              <ul className="modal-menu reset-list-style">
                {wpMenu.menuItems.nodes.map((menuItem, i) => {
                  const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url

                  const itemId = "modal-mobile-menu-item-" + menuItem.databaseId
                
                  if (menuItem.childItems.nodes.length < 1) {
                    
                  return (
                      
                    <li
                      id={itemId}
                      key={itemId}
                      className={
                        "menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home " +
                        itemId
                       
                      }
                      onClick={(e) => toggleBackdrop(e, false)}
                    >
                      <div className="ancestor-wrapper">
                        <UniversalLink
                          to={path}
                          activeClassName={
                            "current-menu-item current_page_item"
                          }
                        >
                          {menuItem.label}
                        </UniversalLink>
                      </div>
                    </li>
                  )
                }else{
                    return (
                     <>
                    
                      <li
                    onClick={(e) => myHandler(e)}
                       
                      id={itemId}
                      key={itemId}
                      className={
                        "menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home " +
                        itemId
                      }
                    >
                      <div className="ancestor-wrapper">
                        <UniversalLink
                          to={path}
                          activeClassName={
                            "current-menu-item current_page_item"
                          }
                        >
                          {menuItem.label}
                        </UniversalLink>
                      </div>
                    </li>
                        <div className="dropdown" ref={myContainer} id="dropdown-mobile-menu">
                        <ul className="modal-menu">     
                              {menuItem.childItems.nodes.map((menuItemm, i) => {

                                    const paths = menuItemm?.connectedNode?.node?.uri ?? menuItemm.url;
                                    const itemIds = "modal-mobile-menu-item-" + menuItemm.databaseId;
                                return(
                      <li
                        id={itemIds}
                        key={itemIds}
                        className={
                          "menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home " +
                          itemIds
                        }
                        onClick={(e) => toggleBackdrop(e, false)}
                      >
                        <div className="ancestor-wrapper">
                          <UniversalLink
                            to={paths}
                            activeClassName={
                              "current-menu-item current_page_item"
                            }
                          >
                            {menuItemm.label}
                          </UniversalLink>
                        </div>
                      </li>
                                )
                       }) }
                       </ul>
                      </div>
                      </>
                    )
                      }
                })}
              </ul>
            </nav>
          </div>

          <div className="menu-bottom">
            <SocialMenu isExpanded />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuModal
