import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import UniversalLink from "./UniversalLink"

const Footer = ({}) => {
  const { wpPage } = useStaticQuery(graphql`
  {
    wpPage(slug: {eq: "options"}) {
      socialLinks {
        facebookLink
        instagramLink
        linkedinLink
      }
      adress {
        city
        mail
        phone
        street
      }
    }
  }
  `)
  return (
    <footer id="site-footer" role="contentinfo" className="header-footer-group footer">
    <Container fluid={true}>
      <Row className="footer-row1">
        <Col lg="1">
        </Col>
        <Col lg="4">
          <div className="footer-icon-col">
            <UniversalLink to="/" className="footer-logo"><img src="/static/LOGO_FOOT-2b7525a5de8a17255fee0cf7eedf2870.png" width="193px" alt='Waterproof logo' /></UniversalLink>
            <div className="footer-social-cont">
              <UniversalLink to={wpPage.socialLinks.instagramLink} className="footer-social-icons"><span className="footer-icon icon-instagram"></span></UniversalLink>
              <UniversalLink to={wpPage.socialLinks.facebookLink} className="footer-social-icons"><span className="footer-icon icon-facebook"></span></UniversalLink>
              <UniversalLink to={wpPage.socialLinks.linkedinLink} className="footer-social-icons"><span className="footer-icon icon-linkedin"></span></UniversalLink>
            </div>
          </div>
        </Col>
        <Col lg="2"></Col>
        <Col lg="4">
          <div className="footer-menu-col">
            <UniversalLink to="/#about-us" className="footer-menu-item">ABOUT US</UniversalLink>
            {/* <UniversalLink to="/our-works" className="footer-menu-item">OUR WORKS</UniversalLink> */}
            <UniversalLink to="/#howwework" className="footer-menu-item">PROCESS</UniversalLink>
          </div>
        </Col>
        <Col lg="1">
        </Col>
      </Row>
      <Row className="footer-row2">
        <Col lg="1">
        </Col>
        <Col lg="4">
          <div className="footer-contact-col">
            <span className="footer-contact-text">{wpPage.adress.street}</span>
            <span className="footer-contact-text">{wpPage.adress.city}</span>
            <span className="footer-contact-text">TEL: <UniversalLink to={"tel:" + wpPage.adress.phone.trim()}>{wpPage.adress.phone}</UniversalLink></span>
            <span className="footer-contact-text"><UniversalLink to={"mailto:"+ wpPage.adress.mail}>{wpPage.adress.mail}</UniversalLink></span>
          </div>
        </Col>
        <Col lg="2"></Col>
        <Col lg="4">
          <span className="footer-services-title">SERVICES</span>
          <div className="footer-services-row">
            <div className="footer-service-cont">
              <span><UniversalLink to="/flat-roof">FLAT ROOF</UniversalLink></span>
            </div>
            <div className="footer-service-cont">
              <span><UniversalLink to="/terrace">TERRACE</UniversalLink></span>
            </div>
            <div className="footer-service-cont">
              <span><UniversalLink to="/green-roof">GREEN ROOF</UniversalLink></span>
            </div>
            <div className="footer-service-cont">
              <span><UniversalLink to="/bathroom">BATHROOM</UniversalLink></span>
            </div>
            <div className="footer-service-cont">
              <span><UniversalLink to="/garage">GARAGE</UniversalLink></span>
            </div>
            <div className="footer-service-cont">
              <span><UniversalLink to="/pool">POOL</UniversalLink></span>
            </div>
            <div className="footer-service-cont">
              <span><UniversalLink to="/basement">BASEMENT</UniversalLink></span>
            </div>
          </div>
        </Col>
        <Col lg="1">
        </Col>
      </Row>
      <Row className="footer-row3">
        <Col>
          <div className="footer-copyright">
            <span>2021 Copyrighted. Liberty Waterproofing. Created by <a className="footer-lemoon" href="http://lemoon-web.pl/"><img src="https://lemoon-web.pl/wp-content/uploads/2017/06/lemoonweb-strony-internetowe-olsztyn-white.png" alt="Strony internetowe Olsztyn"/></a></span>
          </div>
        </Col>
      </Row>
    </Container>
    </footer>
  )
}

export default Footer
